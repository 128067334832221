$darkest-gray: #363E59;
$dark-gray: #737E98;
$gray: #AAB3C8;
$lighter-gray: #BEC4D3;
$lightest-gray: #D3D9E7;

$dark-blue: #498BE2;
$blue: #5384F9;
$light-blue: #789EFF;
$secondary-blue: #C3DDFF;
$white: #FFFFFF;
$background-gray: #F6F8FE;

$light-yellow: #FDF1DE;
$light-green: #F3FFE8;
$orange: #F5A322;
$dark-orange: #FF6F43;
$green: #53BE42;

$text-color: #363E59;
$secondary-text-color: #737E98;

$sidenav-bg-color: #273F77;
$input-bg: #F5F7F9;

$divider-color: rgba(170,179,200, .2);


