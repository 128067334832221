@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/fonts";

@keyframes rotation {
  0% {
    transform: rotate(0)
  }
  25% {
    transform: rotate(90deg)
  }
  50% {
    transform: rotate(180deg)
  }
  75% {
    transform: rotate(270deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.blocking_loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blocking_loader_text {
  font-size: $h3-font-size;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.blocking_loader_icon {
  animation: rotation 2s infinite linear;
  width: 50px;
  svg {
    transform: scale(3);
  }
}
