@import "../../../../../styles/variables/colors";

.input_wrapper {
  display: flex;
  flex-direction: row;
  width: 85px;
  justify-content: space-between;
  align-items: center;
}

.input_value {
  font-weight: 600;
  color: $gray;
}

.input_icon {
  background-color: #DAE7FF;
  color: #5384F9;
  width: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
  
  svg {
    font-size: 14px;
  }

  &:hover {
    // background-color: $blue;
  }
}

.input_square_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 6px 14px;
  width: 64px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(246, 248, 254, 0.8);
  border-radius: 8px;
}

.input_square_value {
  width: 100%;
  margin: 0 0 0 -3px !important;
  font-weight: 600;
  color: #363E59;
  background-color: transparent;
  border: none;
  text-align: center;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
}

.input_square_icon {
  position: absolute;
  color: #5384F9;
  width: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  svg {
    font-size: 10px;
  }

  &:hover {
    // background-color: $blue;
  }
}

.input_square_up_icon {
  @extend .input_square_icon;

  top: 2px;
  right: 12px;
}

.input_square_down_icon {
  @extend .input_square_icon;

  right: 12px;
  bottom: 2px;
}