@import "../../../../../styles/variables/colors";

.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;

    &:hover {
        color: $blue !important;
    }
}

.top_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .actionBarHolder > button {
        height: 40px;
    }
}

.actionBarHolder {
    display: flex;
    align-items: center;

    & > button:first-child {
        margin: 0 18px 0 0;
    } 
}

.checboxItem {
    margin: -17px 0 0;

    svg {
        margin: 0 7px 0 0;
        color: #789EFF;
    }
}

.whenHours {
    display: flex;
    align-items: center;

    & > div:first-child {
        margin: 0 12px 0 0;
    }
}