@import "../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

.dropdown_wrapper {
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.dropdown {
  position: absolute;
  background-color: $white;
  box-shadow: 2px 2px 4px $lighter-gray;
  min-width: 250px;
  right: 0;
  top: 40px;
}

.dropdown_hidden {
  display: none;
}

.input_and_dropdown_wrapper {
  position: relative;
}

.default_dropdown {
  max-height: 150px;
  width: 100%;
  margin-top: 4px;
  position: absolute;
  z-index: 10;
  background-color: $white;
  padding-left: 0;
  margin-left: 0;
  box-shadow: 2px 2px 4px $lighter-gray;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.default_dropdown_item {
  list-style: none;
  cursor: pointer;
  padding: 10px;
  margin-left: 0;
  font-size: 14px;

  @media screen and (max-width: $mobile) {
    font-size: 12px;
  }

  &:hover {
    background-color: $background-gray
  }

  &[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }
}

.root {
  width: 340px;

  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}