@import "../../../../../styles/variables/colors";

.checkbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  label, input {
    cursor: pointer;
  }
}

.checkboxCommon {
  width: auto;
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  margin-right: 5px;

  label, input {
    cursor: pointer;
  }

  label {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -9px;
      left: -22px;
      width: 13px;
      height: 13px;
      border: 1px solid #BEC4D3;
      border-radius: 2px;
    }
  }
}

.checkboxCustom {
  width: auto;
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  margin-right: 5px;

  label, input {
    cursor: pointer;
  }

  label {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: -22px;
      width: 13px;
      height: 13px;
      border: 1px solid #BEC4D3;
      border-radius: 2px;
    }
  }
}

.checkboxSecondary {
  @extend .checkboxCommon;

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 8px;
  margin-right: 0;
  height: 20px;
}

.secondaryInput {
  position: absolute;
  left: -9999px;

  &:checked + label::before {
    background-color: #789EFF;
    background-image: url('../../../icons/check.icon.svg');
    background-position: 13px;
    background-size: 13px;
    border-color: #789EFF;
  }
}

.checkbox_input {
  -webkit-appearance: none;
  background-color: $blue;
  padding: 9px;
  border-radius: 3px;
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  outline: none;
  &:after {
    content: ' ';
    width: 20px;
    height: 20px;
  }
  &:checked {
    color: $white;
  }
  &:checked:after {
    position: absolute;
    content: '\2714';
    font-size: 12px;
    top: 4px;
    left: 5px;
    font-style: normal;
  }
}

.checkbox_label {
  color: $lighter-gray;
  font-weight: 500;
  font-size: 13px;
  margin-left: 3px;
}
