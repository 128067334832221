@import "../../../../../styles/variables/colors";

.title {
  position: relative;
  padding-left: 44px;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.title_divers {
  border-top: 1px solid $divider-color;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  .title_icon {
    top: 15px;
  }
}

.title_text {
}

.title_icon {
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-orange;
  left: 0;
  top: 7px;
  font-size: 11px;
  padding: 5px;
  color: $white;
}
