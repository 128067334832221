@import "../../../styles/variables/colors";
@import "../../../styles/variables/breakpoints";

.auth_layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.auth_layout_logo_section {
  width: 100%;
  height: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_layout_left {
  width: 40%;
  height: 100%;
  @media all and (max-width: $mobile) {
    width: 100%;
  }
}

.auth_layout_right {
  width: 60%;
  background-color: $sidenav-bg-color;
  height: 100%;
  position: relative;
  overflow: hidden;
  @media all and (max-width: $mobile) {
    width: 0;
  }
}

.auth_layout_preview_img {
  position: absolute;
  top: 5%;
  right: 0;
  z-index: 3;
}

.auth_layout_preview_rhombus_small {
  left: 50px;
  top: 60%;
  position: absolute;
}

.auth_layout_preview_rhombus_small_bottom {
  bottom: -70px;
  right: -50px;
  position: absolute;
}

.auth_layout_preview_rhombus_big {
  position: absolute;
  left: 80px;
  top: 0;
}

.auth_layout_title {
  position: absolute;
  bottom: 15%;
  width: 100%;
  * {
    color: $white;
  }
  justify-content: center;
}

.auth_layout_dots {
  position: absolute;
  bottom: 8%;
  left: calc(50% - 40px);
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.auth_layout_dot_active {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  cursor: pointer;
  background-color: $white;
}

.auth_layout_dot_inactive {
  @extend .auth_layout_dot_active;
  opacity: 0.5;
}
