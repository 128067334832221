@import "src/styles/variables/breakpoints";

.agents_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media all and (max-width: $mobile) {
    padding: 20px 0 0;
  }
}

.agent_container {
  margin: 0 0 20px;
  min-width: 250px;
  // padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media all and (max-width: $mobile) {
    margin: 0 0 22px;
  }
}

.avatar {
  height: 30px;
  width: 30px;
  margin-left: 20px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
}

.toolbar-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    // margin: 0 0 18px;
  }

  @media screen and (min-width: $mobile) and (max-width: 1200px) {
    gap: 15px;
  }

}

.control-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    margin: 0 auto 28px;
    width: 94%;

    & > div {
      margin: 0 !important;
    }
  }
}

.toolbar-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;

  @media screen and (max-width: $mobile) {
    // flex-direction: column;
    margin: 0 0 18px;
    flex-wrap: wrap;
    justify-content: center;
    order: 2;
    width: 100%;
  }
}

.back-next-buttons {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;

  @media screen and (max-width: $mobile) {
    margin: 0 45px 0 -10px;
  }

  @media screen and (min-width: $mobile) and (max-width: 1200px) {
    margin: 0 15px 0 -10px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1340px) {
    margin: 0 18px 0 -10px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #2B65F0;
  border: none;
  border-radius: 50%;
  font-size: 10px;
  outline: none;
  cursor: pointer;
}

.btn-back {
  @extend .btns;
  padding: 0 3px 0 0;
}

.btn-next {
  @extend .btns;
  padding: 0 0 0 3px;
}

.label-date {
  margin: 0 15px;

  @media all and (max-width: $mobile) {
    font-size: 12px;
  }

  @media screen and (min-width: $mobile) and (max-width: 1200px) {
    margin: 0 0 0 15px;
  }
}

.wrapper_custom_dropdown {
  margin: 0 48px 0 0;

  @media screen and (min-width: $mobile) and (max-width: 1200px) {
    margin: 0 15px 0 0px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1340px) {
    margin: 0 18px 0 0px;
  }
}

// Buttons
.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.list_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -32px 0 0;

  & > button:not(:last-child) {
    margin: 0 10px 0 0;
  }

  @media all and (max-width: $mobile) {
    order: 1;
    margin: 25px auto 8px;
    // max-width: 220px;
    padding: 0 10px;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > button {
      margin: 0 0 18px 10px !important;
      padding: 9px 7px 9px 13px;
      max-height: 40px;
      width: auto;
    }

    & > button:nth-child(3) {
      padding: 9px 19px 9px 13px;
      width: auto;
    }
  }

  @media screen and (min-width: $mobile) and (max-width: 1200px) {
    margin: -32px 0 0 0px;
  }

  // @media screen and (min-width: $mobile) and (max-width: 1240px) {
  //   max-width: 62px;
  // }
  
}