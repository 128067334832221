@import "../../../../../styles/variables/colors";

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 20px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    color: $blue !important;
  }
}

.top_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.notification_icon {
  border-radius: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}

.icon_email {
  @extend .notification_icon;
  color: $blue;
  background-color: transparentize($blue, 0.85);
}

.icon_phone {
  @extend .notification_icon;
  color: $dark-orange;
  background-color: transparentize($dark-orange, 0.85);
}
