@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';

//* Link Wrapper
.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: $blue !important;
    }

    @media screen and (max-width: $mobile) {
        margin-left: -6px;
        padding-left: 0;
    }
}

//* List Action
.list_action_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selected_action_bar_right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $mobile) {
        justify-content: flex-start;
        align-items: flex-start;

        & > button:first-child {
            // margin: 0 0 18px !important;
            padding: 9px 7px 9px 13px;
            max-height: 40px;
        }
    }
}

.list_action_bar_left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $mobile) {
        margin: 0 0 20px;
        max-width: 360px;
        width: 97.5%;
    
        & > div:first-child {
            width: 100%;
        }
    }
}

.list_action_bar_mobile {
    @media all and (max-width: $mobile) {
        display: flex;
        justify-content: space-between;
        margin: 0 auto 10px;
        padding: 0 10px;
        width: 97.5%;
    }
}

.list_action_props_selected {
    @extend .list_action_bar_left;

    @media screen and (max-width: $mobile) {
        padding: 10px 6px 10px 18px;

        & .list_action_bar_left {
            margin: 0;
        }
    }
}

.save_bar_mobile {
    @media all and (max-width: $mobile) {
        display: flex;
        justify-content: flex-end;
        margin: 0 auto 20px;
        padding: 0 9px;
    }
}

.total {
    color: $gray;
    font-family: "ManropeThin";
    font-size: 16px;
    margin-left: 5px;
}