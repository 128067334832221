@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.dropdown_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 500px;
  max-width: 600px;
  align-items: center;
}

.view_all {
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 10px;
  right: -418px;
  color: $dark-orange;
  cursor: pointer;

  & > a,
  a:visited {
    color: $dark-orange;
  }

  a:hover {
    color: darken($dark-orange, 10%);
  }
}

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 20px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    color: $blue !important;
  }
}

.top_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.tenant_selected {
  padding: 0 0 15px 68px;
  color: #737E98;
  font-family: 'ManropeThin';

  @media screen and (max-width: $mobile) {
    padding: 0 0 15px 28px;
  }
}

.tenant_header {
  margin-top: 10px;
  padding-left: 68px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;

  color: $blue;

  span {
    color: $dark-gray;
  }

  * {
    margin-right: 10px;
  }

  @media screen and (max-width: $mobile) {
    padding-left: 26px;
  }
}

.property_header {
  @extend .tenant_header;
  border-bottom: 1px solid #BEC4D3;
}

.property_content {
  margin: 0 0 28px;
  border-bottom: 1px solid #BEC4D3;
}

.tenants_table {
  padding-left: 68px;
  padding-bottom: 60px;

  @media screen and (max-width: $mobile) {
    padding-left: 26px;
    padding-bottom: 20px;
  }
}

.tenant_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  padding: 10px 0;

  div {
    font-size: 14px;
    // max-width: 350px;
  }

  div:nth-child(1) {
    width: 90%;
    font-weight: bold;
    text-transform: capitalize;

    & > div {
      width: 30%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0 4px 0 0;
    }
  }

  div:nth-child(2) {
    width: 10%;
    opacity: 0.8;
    
    @media screen and (max-width: $mobile) {
      width: 16%;
    }
  }

  div:nth-child(3) {
    width: 30%;
    opacity: 0.8;
  }

  div:nth-child(4) {
    width: 50px;
  }

  @media screen and (max-width: $mobile) {
    width: 100%;

    & > div {
      font-size: 12px;
    }
  }
}

.tenant_row_content {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    flex: 3;
  }
}

.tenant_remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px;
  opacity: 0.7;
  border-radius: 100%;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  text-align: center;

  svg {
    font-size: 16px;
  }

  &:hover {
    opacity: 1;
  }
}

.see_all_icon {
  margin: 2px 8px 0 0;
}

.actionBarHolder {
  display: flex;
  align-items: center;

  & > button:first-child {
      margin: 0 18px 0 0;
  } 
}