@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;

    &:hover {
        color: $blue !important;
    }
}

.top_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .actionBarHolder > button:not(:last-child) {
        height: 40px;
        margin: 0 18px 0 0;
    }
}

.actionBarHolder {
    display: flex;
    align-items: center;

    & > button:first-child {
        margin: 0 18px 0 0;
    } 
}

.inputHolder {
    display: flex;
    align-items: center;
    margin: -34px 0 0;
    width: 100%;
    
    input {
        height: 40px;
    }
}

.notificationHolder {
    display: flex;
    align-items: center;
    margin: -34px 0 0;
}

.iconHolder {
    display: flex;
    align-items: center;
    margin: 0 35px 0 0;

    & > div {
        margin: 0 12px 0 0;
    }
}

.notification_icon {
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin-left: 5px;
    &:first-child {
        margin-left: 0;
    }
}

.icon_email {
    @extend .notification_icon;
    color: $blue;
    background-color: transparentize($blue, 0.85);
}

.icon_phone {
    @extend .notification_icon;
    color: $dark-orange;
    background-color: transparentize($dark-orange, 0.85);
}


.list_action_bar_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexHolder {
    display: flex;
    align-items: center;

    div:first-child {
        width: 20%;
    }

    @media screen and (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-start;
        padding: 12px 0;
    }
}

.groupNotes {
    font-size: 12px;
}

.save_bar_mobile {
    @media all and (max-width: $mobile) {
        display: flex;
        justify-content: space-between;
        margin: 0 18px 24px;
        padding: 10px 0;
        max-width: 320px;
    }
}