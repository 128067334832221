@import "../../../../styles/variables/colors";

.fav_button {
  border-radius: 100%;
  min-width: 40px;
  width: 40px;
  position: relative;
  height: 40px;
  background-color: rgba(190,196,211, .3);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  // padding-top: 10px;
  cursor: pointer;
  font-size: 18px;

  svg {
    color: $lighter-gray;
  }
}

.fav_button_badge {
  position: absolute;
  top: -5px;
  right: -3px;
  background-color: $blue;
  width: 18px;
  font-size: 12px;
  height: 18px;
  color: $white;
  text-align: center;
  border-radius: 100%;
}