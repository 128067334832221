@import "src/styles/variables/breakpoints";

input[type=text] {
  font-family: "ManropeThin";

  &::placeholder {
    color: #BEC1CD;
  }
}

.form_input_wrapper {
  max-width: 539px;

  @media screen and (max-width: $mobile) {
    max-width: 336px;
  }
}

.inputWrapperLarge {
  max-width: 539px;
  width: 100%;

  @media screen and (max-width: $mobile) {
    max-width: 100%;
    width: 100%;
  }
}

.default_form {
  width: 100%;
  // margin: 0 0 0 20px;
  margin: 0 0 0 46px;
  // padding: 0 15px;
  @media screen and (max-width: $mobile) {
    margin: 0 0 0 6px;
    padding: 0;
  }
}

.form_control {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 4px 0;
  @media screen and (max-width: $mobile) {
    //flex-direction: column;
    //height: 65px;
    //width: 100%;
  }
}

.input_wrapper {
  max-width: 539px;
  width: 72%;
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-direction: row;

  @media all and (max-width: $mobile) {
    width: 100%;
    padding: 0 10px 0 0;
    font-size: 12px;
  }
}

// .input_wrapper_large {

// }

.formControl.formControl {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 5px 0;

  @media all and (max-width: $mobile) {
    flex-direction: column;
    height: auto !important;
  }
}