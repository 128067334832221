@import "../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

.tabs {

}

.tabs_wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  max-width: 100vw;

  @media all and (max-width: $mobile) {
    margin-left: 0;
    overflow-x: scroll;
    // margin-bottom: -20px;
  }
}

.tab {
  padding: 20px 25px;
  background-color: $white;
  opacity: 0.6;
  font-weight: 600;
  color: $text-color;
  margin-left: 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: -20px;
  font-size: 14px;

  &:visited {
    color: $text-color;
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    color: $text-color;
  }

  @media all and (max-width: 1328px) {
    font-size: 11px;
  }


  @media all and (max-width: $mobile) {
    padding: 14px 24px;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 0;

  }
}

.active_tab {
  opacity: 1;
  color: $dark-orange !important;
}
