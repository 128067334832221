@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 -6px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
      color: $blue !important;
  }
}

.selected_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
      justify-content: flex-start;
      align-items: flex-start;

      & > button:first-child {
          // margin: 0 0 18px !important;
          padding: 9px 7px 9px 13px;
          max-height: 40
      }
  }
}

.header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.total_tenants {
  color: $gray;
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
}

.last_imported {
  color: $gray;
  font-size: 12px;

  @media all and (max-width: $mobile) {
    padding-left: 15px;
    padding-right: 5px;
    text-align: right;
  }
}
.list_action_bar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    margin: 0 0 20px;
    max-width: 360px;
    width: 97.5%;

    & > div:first-child {
        width: 100%;
    }
  }
}

.property_action_bar_left {
  @extend .list_action_bar_left;

  @media all and (max-width: $mobile) {
    width: 100%;

    // & > div:first-child {
    //     width: 85%;
    // }
  }
}

.list_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
      padding: 0 8px;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      & > button {
          margin: 0 0 22px 10px !important;
          padding: 9px 7px 9px 13px;
          max-height: 40px;
          width: auto;
      }

      & > button:nth-child(2) {
          padding: 9px 19px 9px 13px;
          width: auto;
      }
  }
}

.list_action_bar_props {
  @extend .list_action_bar_right;

  @media all and (max-width: $mobile) {
      margin: 0;
  }
}

.filters_wrapper {
  padding: 15px 15px 0;
}

.notification_method {
  display: flex;
  width: 100%;
  align-items: center;
}

.notification_icon {
  border-radius: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}

.icon_email {
  @extend .notification_icon;
  color: $blue;
  background-color: transparentize($blue, 0.85);
}

.icon_phone {
  @extend .notification_icon;
  color: $dark-orange;
  background-color: transparentize($dark-orange, 0.85);
}

.nameCell {
  display: flex;
  align-items: center;
}

.selected_bar {
  @extend .list_action_bar_right;

  @media all and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 10px;
      padding: 14px 10px 0;
      width: 97.5%;
  }
}

.list_action_bar_mobile {
  @extend .list_action_bar_right;

  @media screen and (max-width: $mobile) {
    justify-content: flex-end;
    margin: 0;
    padding: 0 10px;
    width: 100%;

    & > button {
        margin: 0 0 16px 10px !important;
    }
  }
}

.list_action_props_selected {
  @extend .list_action_bar_left;

  @media screen and (max-width: $mobile) {
      padding: 10px 6px 10px 18px;

      & .list_action_bar_left {
        margin: 0;
      }
  }
}