@import "../../../../styles/variables/colors";

.chip {
  display: inline-flex;
  padding: 0 4px;
  flex-direction: row;
  vertical-align: center;
  margin-top: 5px;
  &:first-child {
    margin-left: 0;
  }
}

.chip_text {

}

.chip_icon {
  cursor: pointer;
  margin-left: 7px;
  transform: scale(1.1);
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
