@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;

    &:hover {
        color: $blue !important;
    }
}

.top_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .actionBarHolder > button {
        height: 40px;
    }
}

.actionBarHolder {
    display: flex;
    align-items: center;

    & > button:first-child {
        margin: 0 18px 0 0;
    } 
}

.list_action_bar_mobile {
    @media all and (max-width: $mobile_minus15) {
        
        display: flex;
        justify-content: space-between;
        max-width: 212px;
        width: 100%;
        margin: 0 auto 20px;
        padding: 0 10px;
    }
}