@import "src/styles//variables/colors";
@import "src/styles//variables/breakpoints";

.searchable_list {
    min-height: 60px;
    max-height: 150px;
    overflow-y: auto;
    margin-top: 10px;
}

.search_item {
    display: flex;
    align-items: center;
    padding: 5px 0;

    &:first-child {
        margin: 0 56px 0 0;
    }
}

.search_label {
    margin-left: 5px;
    font-weight: bold;
}

input:not([type="checkbox"]) {
    max-height: 40px !important;
    height: 100% !important;
}

input[type=number] {
    width: 65%;
    margin: 0 0 0 14px;
}

.input {
    // margin: 22px 0 0;
    padding: 15px 15px;
    width: 100%;
    height: 40px;
    border: 1px solid transparent;
    outline: none;
    transition: all .35s;
    background-color: rgba(246, 248, 254, 0.8);
    border-radius: 8px;
    width: 100%;
    font-weight: 600;
    min-width: 20px;
    &:focus {
        border: 1px solid $blue;
    }
    &::placeholder {
        font-weight: normal;
    }

    &[type="checkbox"] {
        width: auto !important;
    }

    div {
        height: 100%;
    }
}