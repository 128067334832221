@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 -6px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
      color: $blue !important;
  }
}

.total_properties {
  color: $gray;
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
}

.last_imported {
  color: $gray;
  font-size: 12px;

  @media all and (max-width: $mobile) {
    // padding-left: 15px;
    // padding-right: 5px;
    margin: 0 0 10px;
    text-align: left;
  }
}

.delete_properties {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // @media all and (max-width: $mobile) {
  @media all and (max-width: $mobile_minus15) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.list_action_bar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
      margin: 0 0 20px;
      max-width: 360px;
      width: 97.5%;
  
      & > div:first-child {
          width: 85%;
      }
  }
}

.list_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    justify-content: flex-end;
    margin: 0;
    padding: 0 10px;
    width: 100%;

    & > button {
        margin: 0 0 22px 10px !important;
    }

    & > button:first-child {
        padding: 9px 8px 9px 14px;
    }
  }
}

.filters_wrapper {
  padding: 15px;
}

.selected_action_bar_right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > button:not(:last-child) {
    margin: 0 10px 0 0;
  }
}

.nameCell {
  display: flex;
  align-items: center;
}

.list_action_props_selected {
  @extend .list_action_bar_left;

  @media screen and (max-width: $mobile) {
      padding: 10px 6px 10px 18px;

      & .list_action_bar_left {
          margin: 0;
      }
  }
}