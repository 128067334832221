@import '../../../../styles/variables/colors';
@import "src/styles/variables/breakpoints";

.base-style {
  display: block;
  margin: 20px;
  // box-shadow: 0px 0px 15px #fafafb;

  @media all and (max-width: $mobile) {
    margin: 15px 0 0;
  }
}

.panel {
  border-radius: 10px;
  background-color: $white;
  padding: 30px;

  &.sideBorderRadius {
    @media all and (max-width: $mobile) {
      border-radius: 10px 0 0 0;
    }
  }

  @media all and (max-width: $mobile) {
    margin: 0;
    padding: 0 10px;
    width: 100%;
  }

  @extend .base-style;
}

.panel_empty {
  padding: 5px 0 5px 0;
  @extend .base-style;
}

.panel_no_pading {
  @extend .base-style;
  padding: 0;
}

.panel_partial_border {
  @extend .base-style;

  border-radius: 10px 10px 0 0;

  @media screen and (max-width: $mobile) {
    padding: 0;
    border-radius: 10px 0 0 0;
  }
}

.panel_twice_border {
  @extend .base-style;

  @media screen and (max-width: $mobile) {
    padding: 0 0 0 10px;
    border-radius: 10px 0 0 10px;
  }
}