@import "../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

@keyframes pulsating {
  0% {
    transform: scale(1.0) translateY(3px);
  }
  25% {
    transform: scale(1.1) translateY(-3px);
  }
  50% {
    transform: scale(1.2) translateY(-6px);
  }
  75% {
    transform: scale(1.1) translateY(-3px);
  }
  100% {
    transform: scale(1) translateY(6px);
  }
}


.btn-default {
  display: flex;
  align-items: center;
  padding: 9px 19px;
  transition: all .35s;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;

  svg {
    margin: 3px 6px 0 0;
    // transform: scale(1.5);
  }

  @media all and (max-width: $mobile) {
    font-size: 12px;
  }

}

.button_primary {
  @extend .btn-default;
  background: $dark-orange;
  color: $white;
  border: 1px solid transparent;

  svg {
    path {
      stroke: $white;
    }
  }

  &:hover {
    box-shadow: 2px 2px 6px $dark-orange;
  }
}

.loading_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button_outline {
  @extend .btn-default;
  background: transparent;
  color: $dark-orange;
  border: 1px solid $dark-orange;

  svg {
    path {
      stroke: $dark-orange;
    }
  }

  &:hover {
    background: $dark-orange;
    color: $white;

    svg {
      path {
        stroke: $white;
      }
    }
  }
}


.button_disabled {
  border: 1px solid $gray !important;
  color: $gray !important;
  background: transparent !important;
  cursor: not-allowed;

  &:hover {
    box-shadow: none;
  }
}

.button_loading {
  @extend .button_primary;

  &:hover {
    box-shadow: none;
  }

  cursor: not-allowed;
}

.loading_icon_class {
  font-size: 13px;
  animation: pulsating 1s infinite linear !important;
}

.button_icon {
  font-family: 'ManropeThin';
  font-size: 16px;

  svg {
    path {
      stroke-width: .7;
    }
  }
}