.uploaderHolder {
    position: relative;
}

.staffAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    border: 1px solid #BEC4D3;
    color: #BEC4D3;
    margin: 0 14px 0 0;
    overflow: hidden;

    img {
        object-fit: cover;
    }
}

.uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }   
}

.deleteImageBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    bottom: -5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #FF6F43;
    color: white;
    cursor: pointer;
}

.fallbackAbbr {
    letter-spacing: -1px;
    text-transform: uppercase;
}