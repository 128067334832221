@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.last_imported {
  color: $gray;
  font-size: 12px;
  padding-top: 5px;
  @media all and (max-width: $mobile) {
    padding-left: 0;
    padding-right: 5px;
    text-align: right;
  }
}

.header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    padding: 10px 2px;
  }
}

.calendar_header_wrapper {
  @extend .header_wrapper;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    padding: 10px 12px;
  }
}

.total {
  color: $gray;
  font-family: "ManropeThin";
  font-size: 16px;
  margin-left: 5px;
}

// .statistics {

// }

.statistics_header {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  color: $secondary-text-color;

  @media all and (max-width: $mobile) {
    padding: 20px 15px;
  }
}

.statistics_wrapper {
  @media all and (max-width: $mobile) {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }
}

.statistics_row {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  @media all and (max-width: $mobile) {
    width: 850px;
  }
}

.statistics_col {
  width: 25%;
  text-align: center;
  justify-content: center;
  align-items: center;
  // height: 100px;

  &:not(:last-child) {
    border-right: 2px solid transparentize(#789EFF, 0.7);
  }

  @media all and (max-width: $mobile) {
    height: auto;
  }
}

.statistics_col_number {
  font-weight: bold;
  color: $secondary-text-color;
  font-size: 40px;
}

.base_btn {
  align-self: baseline;
  border-radius: 10px;
  margin: 20px auto;
  padding: 8px 20px;
  //padding: 8px 25px;
  //display: inline-block;
  width: fit-content;
  font-family: "ManropeSemibold";
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
}

.btn_overall_meetings {
  @extend .base_btn;
  background-color: #C3CCEC;
  color: $text-color;
}

.btn_meetings {
  @extend .base_btn;
  background: rgba(120, 158, 255, 0.33);
  color: $blue;
}

.btn_showings {
  @extend .base_btn;
  background: #FFE5BC;
  color: $orange;
}

.btn_others {
  @extend .base_btn;
  background: #D5F4D0;
  color: $green;
}


.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile_minus15) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.selected_bar {
  @extend .list_action_bar;

  // @media all and (max-width: $mobile) {
  @media all and (max-width: $mobile_minus15) {
    flex-direction: column;
    margin: 0 0 10px;
    padding: 14px 10px 0;
  }
}

.list_action_bar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    margin: 0 0 20px;
    max-width: 360px;
    width: 97.5%;

    & > div:first-child {
        width: 85%;
    }

    // input {
    //     width: 140%;
    // }
  }

  // @media all and (max-width: $mobile) {
  //   width: 100%;
  //   padding: 0 10px 0 0;
  //   // align-items: flex-start;
  //   // justify-content: flex-start;

  //   input {
  //     width: 140%;
  //   }
  // }
}


.list_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    margin: 25px auto 0;
    // max-width: 220px;
    padding: 0 10px;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > button {
      margin: 0 0 18px 10px !important;
      padding: 9px 7px 9px 13px;
      max-height: 40px;
      width: auto;
    }

    & > button:nth-child(3) {
      padding: 9px 19px 9px 13px;
      width: auto;
    }
  }
}

.list_action_bar_right_top {
  @extend .list_action_bar_right;

  margin: 0;
  align-self: baseline;

  @media all and (min-width: $mobile) {
    margin-left: 15px !important;
  }
}

.filters_wrapper {
  padding: 15px;
}

.table_header {
  margin-top: 5px;
  font-size: 14px;
  padding-top: 17px;
  border-top: 1px solid $lighter-gray;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;

  * {
    margin-right: 10px;
  }

}

.table_wrapper {
  padding-left: 30px;
}

.table {
  padding-left: 0;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  padding: 10px 0;

  div {
    font-size: 14px;
    max-width: 350px;
  }

  div:nth-child(1) {
    width: 30%;
    font-weight: bold;
    text-transform: capitalize;
  }

  div:nth-child(2) {
    width: 30%;
    opacity: 0.8;
  }

  div:nth-child(3) {
    width: 30%;
    opacity: 0.8;
  }

  div:nth-child(4) {
    width: 50px;
  }
}

.remove_icon {
  width: 30px !important;
  height: 30px;
  padding-top: 3px;
  opacity: 0.7;
  border-radius: 100%;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: 1;
  }
}

.prospects_redirect_link {
  // position: absolute;
  z-index: 3;
  // top: 40px;
  // left: 400px;
  display: flex;
  align-items: center;
  color: $dark-orange;
  font-size: 14px;
  font-family: 'ManropeSemibold';
  white-space: nowrap;
  cursor: pointer;

  &:visited {
    color: $dark-orange;
  }

  &:hover {
    color: darken($dark-orange, 10%);
  }
}

.selected_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 238px;
  width: 100%;

  @media all and (max-width: $mobile) {
      justify-content: space-between;
      align-items: flex-start;
      max-width: 102px;

      & > button {
          // margin: 0 0 18px !important;
          padding: 9px 7px 9px 13px;
          max-height: 40px;
      }
  }
}

.selected_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
}

/* Export Calendar */
.export_chose {
  color: #737E98;
  font-size: 12px;
}

.export_holder {
  margin: 0 0 20px;
  padding: 0;
}

.export_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  color: #789EFF;
  font-size: 18px;
  font-weight: bold;
  list-style: none;
  cursor: pointer;

  & > span {
    color: #363E59;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $divider-color;
  }
}

.export_title {
  padding: 20px 0;
  color: #363E59;
  font-size: 18px;
  font-weight: bold;
  list-style: none;

  &:first-child {
    border-bottom: 1px solid $divider-color;
  }
}

.right_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.busy_icon.busy_icon {
  position: absolute;
  right: 62px;
  display: flex;
  align-items: center;
  font-size: 16px;
  
  span {
    display: inline-block;
    margin: 0 0 0 4px;
    text-transform: none;
    font-weight: normal;
    font-size: 12px;
  }
}

.optionHolder {
  display: flex;
  align-items: center;
}

.optionIcon {
  width: 20px;
  height: 18px;
  margin: 0;
  color: #789EFF;
}

.selectedCount {
  padding-top: 20px;
  color: #363E59;
  font-size: 14px;
  font-family: 'ManropeRegular';
}

.allProperties {
  position: absolute; 
  top: 17px;
  right: 0;

  @media screen and (max-width: $mobile) {
    position: relative;
    top: 16px;
    left: 0;
    margin: 0 0 18px;
  }
}

.propertiesHolder {
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.list_action_props_selected {
  @extend .list_action_bar_left;

  @media screen and (max-width: $mobile) {
      padding: 18px 6px 18px 18px;

      & .list_action_bar_left {
          margin: 0;
      }
  }
}

.iconHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 5px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: #465f92;
  font-size: 14px;

  svg {
    margin-left: 0;
  }
}