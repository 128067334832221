@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";
.base_styles {
  border-radius: 8px;
}

.search_wrapper {
  position: relative;
  display: inline-block;
}

.search_wrapper_focused {
  .search_icon {
    color: $blue;
  }
}

.search_input_secondary {
  @extend .base_styles;
  background-color: $background-gray;
  border: 1px solid transparent;
  padding: 12px 10px 12px 40px;
  outline: none;

  &:focus {
    border: 1px solid $blue;
  }

  &::placeholder {
    color: $lighter-gray;
  }
}

.search_input_primary {
  @extend .base_styles;
  border: none;
  background-color: $white;
  padding: 12px 15px 12px 40px;
  width: 300px;
  outline: none;

  @media all and (max-width: $mobile) {
    width: 100%;
  }
  &::placeholder {
    color: $lighter-gray;
  }
}

.search_input_main {
  @extend .base_styles;
  background-color: $white;
  padding: 12px 15px 12px 40px;
  outline: none;
  border: 1px solid $lighter-gray;

  &::placeholder {
    color: $lighter-gray;
  }

  &:focus {
    border: 1px solid $blue;
  }
}

.search_icon {
  position: absolute;
  left: 10.5px;
  top: 50%;
  transform: translateY(-44%);
  color: $gray;
}
