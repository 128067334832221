@import 'src/styles/variables/breakpoints';

.panelTop {
    display: flex;
    justify-content: space-between;
}

.addTemplateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF6F43;
    font-size: 14px;
    cursor: pointer;

    // @media screen and (max-width: $mobile) {
    @media screen and (max-width: $mobile_minus15) {
        font-size: 12px;
    }
}

.addSysButton {
    @extend .addTemplateButton;
    position: absolute;
    right: 120px;

    // @media screen and (max-width: 1200px) {
    @media screen and (max-width: $mobile_minus15) {
        position: absolute;
        top: 15px;
        right: 14px;
        left: auto;
    }
}

.addIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
    width: 18px;
    height: 18px;
    background: #FF6F43;
    border-radius: 50%;

    svg {
        fill: #fff;
    }
}

.moreOptionsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #BEC4D3;
    background-color: #f2f3f6;
    cursor: pointer;
}

.moreIcon {
    position: relative;
    top: 2px;
    width: 30px;
    color: #AAB3C8;
}

.moreName {
    font-family: 'ManropeThin';
}

.list_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
    min-width: 240px;
    width: 100%;
}

.list_userName {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #363E59;
}

.list_userColor {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.actionBarHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actionBarHolderEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bodyHolder {
    display: flex;
    align-items: flex-start;
    width: 100%;

    // @media screen and (max-width: 1200px) {
    @media screen and (max-width: $mobile_minus15) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.buttonText {
    white-space: nowrap;
}

.colorsHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 72px;

    // @media screen and (max-width: 1200px) {
    @media screen and (max-width: $mobile_minus15) {
        margin-bottom: 20px;
    }
}

.itemColor {
    position: relative;
    margin: 0 16% 20px 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    
    &:nth-child(4n) {
        margin-right: 0;
    }

    // @media screen and (max-width: 1200px) {
    //     margin: 0 41px 20px 0;
    // }
}

.itemControl {
    display: block;
    border-radius: 50%;
}

.itemRadio {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
        position: absolute;
        top: -50%;
        left: -50%;
        width: 42px;
        height: 42px;
        border: 1px solid #363E59;
        background-color: transparent;
        transform: translate(26%, 26%);
    }
}

.badge {
    padding: 8px;
    width: 156px;
    height: 56px;
    font-size: 12px;
    box-shadow: 8px 8px 25px rgba(0, 0, 0, .1);
    border-radius: 4px;

    & > div:first-child {
        margin-bottom: 8px;
    }
}

.badgeContent {
    color: #737E98;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 464px;
    height: 450px; 
    padding: 0 14px 0;

    // @media screen and (max-width: 1200px) {
    @media screen and (max-width: $mobile_minus15) {
        min-width: 240px;
        width: 100%;
        height: 260px;
    }
}