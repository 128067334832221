@import "../../../../styles/variables/colors";

.sidenav {
  // height: 100vh;
  min-width: 252px;
  max-width: 252px;
  width: 100%;
  z-index: 999;
  background-color: $sidenav-bg-color;
  transition: ease-in-out .4s;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }

  a {
    color: $white;
  }

  * {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.7px;

  }
}

.sidenav_logo {
  background-color: $white;
  display: flex;
  padding: 0 0 0 20px;
  width: 100%;
  justify-content: flex-start;
  height: 52px;
  align-items: center;
}

.sidenav_routes {
  padding: 30px 10px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidenav_route_group {
  margin: 20px auto;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sidenav_icon_side {
  width: 30%;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $white;

  svg {
    margin-top: 5px;
    font-size: 18px;
    opacity: .6;
    font-family: 'ManropeRegular';
  }

  @media screen and (max-width: 1200px) {
    width: 18%;
  }
}


.sidenav_icon {
  position: absolute;
  // margin-top: -9px;
  padding: 1px 7px;
  font-size: 20px;
}



.sidenav_icon_side_active {
  .sidenav_icon {
    background-color: $dark-orange;
    border-radius: 8px;
  }

  svg {
    opacity: 1;
  }
}

.sidenav_links_side {
  width: 70%;
}

.sidenav_sublink {
  margin-top: 15px;
}

.sidenav_sublink_children {
  &:first-child {
    margin-top: 25px;

  }
}

.sidenav_link {
  opacity: 0.6;
}

.sidenav_active_link {
  opacity: 1;
}

.sidenav_active_link_main {
  @extend .sidenav_active_link;
}
