@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/breakpoints";

.table_no_title {

}

.table {
    & > div {
        @media screen and (max-width: $mobile) {
            display: table-caption;
            width: 175%;
            // width: 104%;
        }
    }
}

.table_caption {
    & > div {
        display: table-caption;
        width: 175%;
    }
}
