@import "./variables/colors";
@import "./overrides/material-override";

@font-face {
  font-family: 'ManropeBold';
  src: url('fonts/manrope/bold.eot');
  src: url('fonts/manrope/bold.eot') format('embedded-opentype'),
       url('fonts/manrope/bold.woff2') format('woff2'),
       url('fonts/manrope/bold.woff') format('woff'),
       url('fonts/manrope/bold.ttf') format('truetype'),
       url('fonts/manrope/bold.svg#bold') format('svg');
}

@font-face {
  font-family: 'ManropeLight';
  src: url('fonts/manrope/light.eot');
  src: url('fonts/manrope/light.eot') format('embedded-opentype'),
       url('fonts/manrope/light.woff2') format('woff2'),
       url('fonts/manrope/light.woff') format('woff'),
       url('fonts/manrope/light.ttf') format('truetype'),
       url('fonts/manrope/light.svg#light') format('svg');
}

@font-face {
  font-family: 'ManropeMedium';
  src: url('fonts/manrope/medium.eot');
  src: url('fonts/manrope/medium.eot') format('embedded-opentype'),
       url('fonts/manrope/medium.woff2') format('woff2'),
       url('fonts/manrope/medium.woff') format('woff'),
       url('fonts/manrope/medium.ttf') format('truetype'),
       url('fonts/manrope/medium.svg#medium') format('svg');
}

@font-face {
  font-family: 'ManropeRegular';
  src: url('fonts/manrope/regular.eot');
  src: url('fonts/manrope/regular.eot') format('embedded-opentype'),
       url('fonts/manrope/regular.woff2') format('woff2'),
       url('fonts/manrope/regular.woff') format('woff'),
       url('fonts/manrope/regular.ttf') format('truetype'),
       url('fonts/manrope/regular.svg#regular') format('svg');
}

@font-face {
  font-family: 'ManropeSemibold';
  src: url('fonts/manrope/semibold.eot');
  src: url('fonts/manrope/semibold.eot') format('embedded-opentype'),
       url('fonts/manrope/semibold.woff2') format('woff2'),
       url('fonts/manrope/semibold.woff') format('woff'),
       url('fonts/manrope/semibold.ttf') format('truetype'),
       url('fonts/manrope/semibold.svg#semibold') format('svg');
}

@font-face {
  font-family: 'ManropeThin';
  src: url('fonts/manrope/thin.eot');
  src: url('fonts/manrope/thin.eot') format('embedded-opentype'),
       url('fonts/manrope/thin.woff2') format('woff2'),
       url('fonts/manrope/thin.woff') format('woff'),
       url('fonts/manrope/thin.ttf') format('truetype'),
       url('fonts/manrope/thin.svg#thin') format('svg');
}


$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

//$calendar-border: transparent;
$cell-border: $lightest-gray !default;

$border-color: $background-gray !default;

$segment-width: percentage(1 / 7) !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0,0,0, .50) !default;
$date-selection-bg-color: rgba(0,0,0, .10) !default;


$event-bg: #C3DDFF !default;
$event-border: transparent;
$event-outline: #C3DDFF !default;
$event-color: #498BE2 !default;
$event-border-radius: 5px !default;
//$event-padding: 5px 10px !default;
$event-zindex: 4 !default;

$btn-color: $dark-orange !default;
$btn-bg: #fff !default;
$btn-border: $dark-orange !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: transparentize($orange, 0.9) !default;

@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD
@import 'react-big-calendar/lib/sass/styles';
@import './overrides/calendar-override';

body {
  color: $text-color;
  // font-family: 'Proxima Nova', "Roboto Light", sans-serif, serif;
  box-sizing: border-box;

  font-family: 'ManropeRegular', 'ManropeBold', 'ManropeLight', 'ManropeMedium', 'ManropeSemibold', 'ManropeThin';
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:visited {
    text-decoration: none;
    // color: inherit;
  }
}

body {
  margin: 0;
}

a {
  &:hover {
    color: $blue;
  }
}

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 20px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;

  &:hover {
    color: $blue !important;
  }
}

.html2canvas-container { height: 3000px !important; }