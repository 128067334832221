@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.list_action_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // @media all and (max-width: $mobile) {
    @media all and (max-width: $mobile_minus15) {
        flex-direction: column;
        align-items: flex-start;
    }
}


.header_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.total_tenants {
    color: $gray;
    font-weight: normal;
    font-size: 14px;
    margin-left: 5px;
}

.last_imported {
    color: $gray;
    font-size: 12px;
    @media all and (max-width: $mobile) {
        padding-left: 15px;
        padding-right: 5px;
        text-align: right;
    }
}
.list_action_bar_left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $mobile) {
        margin: 0 0 20px;
        max-width: 360px;
        width: 97.5%;
    
        & > div:first-child {
            width: 85%;
        }

        // input {
        //     width: 140%;
        // }
    }

}

.list_action_bar_right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button:not(:last-child) {
        margin: 0 16px 0 0;
    }

    @media all and (min-width: $mobile_minus15) and (max-width: $mobile) {
        margin: 0 0 20px;
    }

    // @media all and (max-width: $mobile) {
    @media all and (max-width: $mobile_minus15) {
        margin: 25px auto 0;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    
        & > button {
            margin: 0 0 18px !important;
            padding: 9px 7px 9px 13px;
            max-height: 40px;
            width: auto;
        }

        & > button:nth-child(2) {
            padding: 9px 19px 9px 13px;
            width: auto;
        }
    }
}

.selected_bar {
    @extend .list_action_bar_right;

    @media all and (max-width: $mobile) {
        flex-direction: column;
        margin: 0 0 10px;
        padding: 14px 10px 0;
        width: 97.5%;
    }
}

.list_action_bar_mobile {
    @extend .list_action_bar_right;

    // @media (min-width: $mobile) and (max-width: $mobile_plus15) {
    //     justify-content: flex-end;
    // }
    

    @media all and (max-width: $mobile) {
        justify-content: flex-end;
        margin: 0;
        padding: 0 10px;
        width: 100%;

        & > button {
            margin: 0 0 16px 10px !important;
        }
    }
}

.selected_action_bar_right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $mobile) {
        justify-content: flex-start;
        align-items: flex-start;

        & > button:first-child {
            // margin: 0 0 18px !important;
            padding: 9px 7px 9px 13px;
            max-height: 40px;
        }
    }
}

.filters_wrapper {
    padding: 15px;
}

.notification_method {
    display: flex;
    width: 100%;
    align-items: center;
}

.notification_icon {
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin-left: 5px;
    &:first-child {
        margin-left: 0;
    }
}

.icon_email {
    @extend .notification_icon;
    color: $blue;
    background-color: transparentize($blue, 0.85);
}

.icon_phone {
    @extend .notification_icon;
    color: $dark-orange;
    background-color: transparentize($dark-orange, 0.85);
}

.icon_table {
    margin: 0 6px 0 0;

    &_holder {
        display: flex;
        align-items: center;
    }
}

.nameCell {
    display: flex;
    align-items: center;
}

.staffAvatar {
    border-radius: 50%;
    margin: 0 14px 0 0;
    overflow: hidden;
}

.list_action_props_selected {
    @extend .list_action_bar_left;

    @media screen and (max-width: $mobile) {
        padding: 10px 6px 10px 18px;

        & .list_action_bar_left {
            margin: 0;
        }
    }
}