@import "../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

$sidenav-header: 97px;
$sidenav-footer: 80px;

.sidenav {
  position: fixed;
  top: 0;
  right: 0;
  // width: 100%;
  height: 100vh;
  // overflow: hidden;
  z-index: 1001;
  transition: all ease-out .4s;
  transform: translateX(100%);
}

.active {
  transform: translateX(0);
}

.sidenav_header {
  height: $sidenav-header;
  border-top-left-radius: 12px;
  background-color: #273F77;
  width: 100%;
  color: $white;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding: 14px;
  font-size: 20px;

  &.with_icon {
    padding: 29px;

    @media all and (max-width: $mobile) {
      padding: 20px;
    }
  }

  * {
    margin-left: 5px;
  }

  @media all and (max-width: $mobile) {
    padding: 10px;
  }
}

.sidenav_close {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
  color: darken($white, 20%);
  &:hover {
    color: $white;
  }
}

.sidenav_wrapper {
  // height: calc(100vh - 97px);
  // display: flex;
  // flex-direction: column;
  height: 100%;
}
.sidenav_body {
  padding: 20px 20px 60px;
  width: 100%;
  height: 82%;
  background-color: $white;
  overflow-y: auto;
  // overflow-x: hidden;
  // min-height: calc(100vh - 92px - 80px);

  @media all and (max-width: $mobile) {
    padding: 10px;
  }
}

.sidenav_footer {
  position: absolute;
  width: 100%;
  bottom: 0;

  background-color: $background-gray;
  padding: 20px;
  display: flex;
  // height: $sidenav-footer;
  // height: 10%;
  flex-direction: row;
  justify-content: flex-end;
}

.sidenav_background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: opacity ease 0.5s, left ease 0s;
}