@import "../variables/colors";

.MuiAutocomplete-paper {
  box-shadow: 2px 2px 15px lighten(transparentize($gray, 0.4), 10%) !important;
}

.MuiAutocomplete-option {
  font-family: 'ManropeSemibold';
  font-size: 14px;
  color: $text-color;
}
