@import "../../../../styles/variables/fonts";

.small-margin {
  margin: 10px auto;
}

.h1 {
  font-family: 'ManropeBold';
  font-size: $h1-font-size;
  @extend .small-margin;
}

.h2 {
  font-family: 'ManropeBold';
  font-size: $h2-font-size;
  @extend .small-margin;
}

.h3 {
  font-family: 'ManropeSemibold';
  font-size: $h3-font-size;
  @extend .small-margin;
}


.h4 {
  font-family: 'ManropeSemibold';
  font-size: $h4-font-size;
  @extend .small-margin;
}

.h5 {
  font-weight: 500;
  font-size: $h5-font-size;
  @extend .small-margin;
}

.h6 {
  font-weight: 500;
  font-size: $h6-font-size;
  @extend .small-margin;
}
