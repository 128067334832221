@import "../../../../../styles/variables/colors";

.wrapper {
  padding-bottom: 20px;
}

.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.list_action_bar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 20px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;
  &:hover {
    color: $blue !important;
  }
}

.top_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}


.form_wrapper {
  display: flex;
  flex-direction: column;
}
