@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: $blue !important;
    }
}

.top_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .actionBarHolder > button {
        height: 40px;
    }
}

.actionBarHolder {
    display: flex;
    align-items: center;

    & > button:first-child {
        // margin: 0 18px 0 0;
    } 
}

.inputHolder {
    display: flex;
    align-items: center;
    margin: -34px 0 0;
    width: 100%;
    
    input {
        height: 40px;
    }
}

.notificationHolder {
    display: flex;
    align-items: center;
    margin: -34px 0 0;
}

.iconHolder {
    display: flex;
    align-items: center;
    margin: 0 35px 0 0;

    & > div {
        margin: 0 12px 0 0;
    }
}

.notification_icon {
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin-left: 5px;
    &:first-child {
        margin-left: 0;
    }
}

.icon_email {
    @extend .notification_icon;
    color: $blue;
    background-color: transparentize($blue, 0.85);
}

.icon_phone {
    @extend .notification_icon;
    color: $dark-orange;
    background-color: transparentize($dark-orange, 0.85);
}

.dropdown_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 500px;
    max-width: 600px;
    align-items: center;

    @media screen and (max-width: $mobile) {
        min-width: auto;
        max-width: 100%;
        width: 100%;
    }
}

.view_all {
    color: $dark-orange;
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    a:hover {
        color: darken($dark-orange, 10%);
    }

    a:visited {
        color: $dark-orange;
    }
}

// Render Selected
.selected_list {
    padding: 18px 0;
    min-width: 400px;
    width: 100%;

    @media (max-width: 992px) {
        min-width: 300px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #D3D9E7;
    }
}

.selected_divider {
    // border-bottom: 1px solid #D3D9E7;
}

.selected_table {
    width: 100%;
    padding-left: 0;
}

.selected_header {
    display: flex;
    align-items: center;
}

.selected_icon {
    width: 20px;
    margin: 3px 4px 0 0;
    color: #789EFF;
}

.selected_title {
    font-size: 12px;
}

.selected_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    padding: 10px 0;

    div {
        font-size: 14px;
        // max-width: 350px;
    }

    div:nth-child(1) {
        // width: 30%;
        font-weight: bold;
        text-transform: capitalize;
    }

    div:nth-child(2) {
        // width: 30%;
        opacity: 0.8;
    }

    div:nth-child(3) {
        width: 30%;
        opacity: 0.8;
    }

    div:nth-child(4) {
        width: 50px;
    }
}

.selected_remove {
    position: relative;
    right: 0;
    width: 20px !important;
    height: 20px !important;
    color: #fff;
    border-radius: 50%;
    background: #789EFF;
    font-size: 12px;
    cursor: pointer;
}

.selectedHolder {
    width: 100%;
}

.editorWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.editorHolder {
    // margin: 0 18px;
    width: 100%;
    min-height: 215px !important;
    height: auto;
    padding: 18px;
    border-radius: 8px;
    background-color: rgba(246, 248, 254, 0.8);
    font-family: 'ManropeThin';

    & .DraftEditor-root .public-DraftEditor-content {
        min-height: 200px !important;
    }
}

.editorCotrols {
    display: flex;
    align-items: center;
    margin: 18px 0 0 0;
    background-color: #fff;
}

.editorBtn {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: #F8F9FE;
    color: #789EFF;
    font-size: 14px;
    cursor: pointer;

    &:focus,
    &:active {
        outline: none !important;
    }

    &:not(:last-child) {
        margin: 0 10px 0 0;
    }
}

.customBlockHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // background-color: rgba(238,242,255,0.8);
}

.groupEmails {
    // color: #BEC4D3;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
}

.optionHolder {
    display: flex;
    align-items: center;
}

.optionIcon {
    width: 20px;
    height: 18px;
    margin: 0;
    color: #789EFF;
}

.selectedCount {
    padding-top: 20px;
    color: #363E59;
    font-size: 14px;
    font-family: 'ManropeThin';
}

// --------------
.addTemplateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF6F43;
    font-size: 14px;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
        font-size: 12px;
    }
}

.addSysButton {
    @extend .addTemplateButton;
    position: absolute;
    right: 120px;

    @media screen and (max-width: 1400px) {
        position: absolute;
        top: -40px;
        right: 58px;
        left: auto;
    }

    @media screen and (max-width: 1200px) {
        position: absolute;
        top: 15px;
        right: 14px;
        left: auto;
    }
}

.addIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
    width: 18px;
    height: 18px;
    background: #FF6F43;
    border-radius: 50%;

    svg {
        fill: #fff;
    }
}