@import "../variables/colors";
@import "../variables/breakpoints";

.rbc-calendar {
  color: $dark-gray;

  a {
    &:hover {
      color: $dark-orange;
    }
  }
}

@media all and (max-width: $mobile) {
  //.rbc-timeslot-group {
  //  font-size: 12px;
  //}
  //.rbc-header {
  //  font-size: 12px;
  //}
}

.rbc-toolbar {
  @media all and (max-width: $mobile) {
    flex-direction: column;
    .rbc-btn-group:first-child {
      //display: none;
      position: relative;
      top: 48px;
      margin-top: -35px;

      button:nth-child(1) {
        display: none;
      }

      button:nth-child(2) {
        font-size: 0;
        background-color: $background-gray !important;
        border-radius: 100% !important;
        padding: 10px !important;
        width: 40px;
        border: 1px solid transparent;
        margin-right: calc(100vw - 150px);

        &:hover {
          border: 1px solid $blue;
        }

        &:before {
          content: '<';
          font-size: 14px;
          color: $blue;
        }
      }

      button:nth-child(3) {
        font-size: 0;
        background-color: $background-gray !important;
        padding: 12px !important;
        width: 40px;
        border-radius: 100% !important;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $blue;
        }

        &:before {
          content: '>';
          font-size: 14px;
          color: $blue;
        }
      }
    }
    .rbc-toolbar-label {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  button {
    outline: none !important;
    cursor: pointer;
  }

  & button {
    &:active,
    &.rbc-active {
      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }
}

.rbc-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    &:not(:first-child) {
      margin-left: 10px;
    }

    background: transparent !important;
    border-radius: 4px !important;
    cursor: pointer;

    &:hover {
      background: $dark-orange !important;
      color: $white;
    }

    &:active {
      color: $white;
    }

    &:visited {
      background: transparent !important;
      color: $white;
    }
  }

  .rbc-active {
    background: $dark-orange !important;
    color: $white !important;
  }
}

.rbc-active {
  background: $dark-orange !important;
  color: $white !important;
}

.rbc-month-header {
  margin-top: 10px;
}

.rbc-time-header-content {
  margin-top: 20px;
}

.rbc-header {
  min-height: 45px;
  vertical-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

  @media all and (max-width: $mobile) {
    font-size: 70%;
  }
}

.rbc-time-header {
  border: none;
  border-right: none !important;
}

.rbc-time-content {
  border-top: none;
}

.rbc-current-time-indicator {
  background-color: $dark-orange;
}

.rbc-time-view {
  border: none;
}

.rbc-header {
  border-bottom: 1px solid #f5f5f7;

  span {
    color: $lighter-gray;
    font-weight: 500;

    @media all and (max-width: $mobile) {
        font-size: 8px;
    }
  }
}

.rbc-time-slot {
  .rbc-label {
    color: $lighter-gray;
    font-weight: 500;

    @media all and (max-width: $mobile) {
      font-size: 8px;
    }
  }
}

.rbc-event {
  border-left: 1px solid white !important;
  border-bottom: 1px solid white !important;

  height: 10px;
  overflow: hidden;

  .rbc-event-content {
    @media all and (max-width: $mobile) {
      font-size: 10px;
      white-space: nowrap;
    }
  }
}

.rbc-date-cell {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-top: 6px;
  text-align: center;
  color: #BEC4D3;

  @media screen and (max-height: 820px) {
    width: 16px;
    height: 16px;
    font-size: 12px;
  }

  &.rbc-now a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    background-color: #5384F9;
    color: #fff;

    @media screen and (max-height: 820px) {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }
  }

  @media all and (max-width: $mobile) {
    font-size: 10px;
  }
}

.rbc-timeslot-group {
  height: 60px;
}

.rbc-events-container {
  & > div:not(:first-child) {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    white-space: nowrap;
    line-break: normal;
  }
}

.rbc-event {
  padding: 0 5px;
  font-family: 'Proxima Nova', "Roboto Light", sans-serif, serif;
  font-weight: normal;
  flex-direction: column-reverse !important;
  font-family: 'ManropeSemibold';
  font-size: 12px;
  min-height: 30px;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: 15px 15px 40px transparentize($gray, 0.7);

  .rbc-event-content {
    font-weight: 500;
  }
}

.rbc-event-label {
  display: none;
  padding: 5px 0;
  font-family: 'ManropeRegular';
  font-size: 90%;
  color: #737E98;
}

.rbc-time-gutter {
  .rbc-timeslot-group {
    border-bottom: none;
    position: relative;

    .rbc-label {
      margin-top: -20px;
      //position: absolute;
      //top: -20px;
    }
  }
}

.rbc-now {
  background-color: transparent;
}

.rbc-today {
  background: transparent;
}


.rbc-header.rbc-today {
  background-color: $blue;
  box-shadow: 12px 12px 34px transparentize($blue, 0.6);
  border-radius: 8px;

  * {
    color: $white !important;
  }
}

.rbc-month-view {
  border: 1px solid #f5f5f7;
  border-top: none;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #f5f5f7;
}

.rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #f5f5f7;
}

.rbc-time-header-content {
  border-left: 1px solid #f5f5f7;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #f5f5f7;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #f5f5f7;
}

.rbc-show-more {
  font-size: 12px;
  color: rgba(115,126,152,1);
}