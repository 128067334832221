@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.wrapper {
  padding-bottom: 20px;
}

.link_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-top: 20px;
  color: $gray !important;
  font-size: 18px;
  font-weight: 300;

  &:hover {
      color: $blue !important;
  }

  @media screen and (max-width: $mobile) {
      margin-left: -6px;
      padding-left: 0;
  }
}

.top_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}


.form_wrapper {
  display: flex;
  flex-direction: column;
}

.portfolio_search {
  margin-left: -50px;
  margin-top: 50px;
}

.portfolio_name {
  position: absolute;
  font-weight: 600;
  left: calc(55% + 20px);
  top: -35px;
}

.portfolio_add {
  width: 136px;
  color: $dark-orange;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  margin-top: 40px;
  align-items: center;
  margin-left: -5px;
  justify-content: space-between;
}

.portfolio_selection {
  display: flex;
  flex-direction: column;
}

.portfolio_selection_item {
  padding-top: 15px;
  font-weight: 600;
  cursor: pointer;
}

.portfolio_radio {
  padding-left: 0;
  margin-left: 0;
  margin-right: 15px;
}

.portfolio_autocomplete {
  div {
    padding: 0;
  }
  fieldset {
    display: none;
  }
}

.portfolio_create_input {
  padding: 10px 0;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.portfolio_create_input_hint {
  color: $gray;
  font-weight: 400;
}

.portfolio_create_input_icon {
  color: lighten($blue, 20%);
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}

.portfolio_amenities {
  @extend .portfolio_autocomplete;
}

.amenities_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}

.amenititesHolder {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.amenityIcon {
  display: flex;
  margin: 0 64px 14px 0;
  align-items: center;
  color: #789EFF;

  span {
    display: inline-block;
    margin: 0 0 0 8px;
    color: #363E59;
  }
}

.portfolio_section {
  display: flex;
  align-items: center;
  border-top: 1px solid $divider-color;
  padding: 16px 0;

  div:first-child {
    width: 235px;
  }
}