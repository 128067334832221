@import "../../../../styles/variables/colors";

.forgot_password {
  display: flex;
  width: 300px;
  margin: 20px auto;
  flex-direction: column;
}

.forgot_password_form {
  height: 140px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.forgot_password_link {
  text-decoration: underline;
}

.forgot_password_back_link {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
}
