@import "../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

.list_action_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.total_properties {
  color: $gray;
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
}

.last_imported {
  color: $gray;
  font-size: 12px;

  @media all and (max-width: $mobile) {
    padding-left: 15px;
    text-align: right;
    padding-right: 5px;
  }
}

.last_imported_mobile {
  @extend .last_imported;

  margin: 0 0 10px;
  padding: 0;
  text-align: left;
}

.delete_properties {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list_action_bar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: $mobile) {
    margin: 0 0 20px;
    width: 97.5%;

    & > div:first-child {
      width: 85%;
    }
  }
}

.list_action_bar_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters_wrapper {
  padding: 15px 15px 0;
}

.selected_action_bar_right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > button:not(:last-child) {
    margin: 0 10px 0 0;
  }
}

.mobileButton {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  width: 100%;
}
