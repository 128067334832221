@import "../../../../../styles/variables/colors";
@import "src/styles/variables/breakpoints";

.input_wrapper {
  position: relative;
  width: 100%;
}

.password_icon {
  position: absolute;
  transform: scale(1.5);
  right: 14px;
  top: 14px;
  cursor: pointer;
}

.input {
  padding: 15px 15px;
  border: 1px solid transparent;
  outline: none;
  transition: all .35s;
  background: rgba(246, 248, 254, 0.8);
  border-radius: 8px;
  width: 100%;
  font-family: "ManropeThin";
  min-width: 20px;
  // box-shadow: 15px 15px 40px rgba(225, 234, 244, 0.37);
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    font-weight: normal;
  }

  &[type="checkbox"] {
    width: auto !important;
  }
}

.timePicker {
  @media screen and (max-width: $mobile) {
    padding: 15px 8px;
    max-width: 76px;
  }
}

.input_password {
  letter-spacing: 4px;
}

.input_invalid {
  border-color: red;
}

.errorMsg {
  margin: 8px 0 0;
  color: red;
}

.calendar_currentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 33px;
  height: 33px;
  background-color: #5384F9;
  box-shadow: 10px 8px 9px rgba(83, 132, 249, 0.3);
  border-radius: 4px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.calendar_selectedDate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 33px;
  height: 33px;
  background-color: rgba(83,132,249, .2);
  border-radius: 4px;
  color: #737E98;
  border: none;
  outline: none;
}

.calendar_date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 33px;
  height: 33px;
  background-color: transparent;
  color: #737E98;
  border: none;
  outline: none;
  cursor: pointer;
}

.out_date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 33px;
  height: 33px;
  background-color: transparent;
  color: #737E98;
  opacity: 0.5;
  border: none;
  outline: none;
}