.sign_in {
  display: flex;
  width: 300px;
  margin: 20px auto;
  flex-direction: column;
}

.sign_in_form {
  height: 240px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sign_in_checkbox_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}


.sign_in_forgot_password {
  width: 55%;
  white-space: nowrap;
  font-size: 13px;
  height: 100%;
  padding-top: 11px;
  
  a {
    color: #363E59;
  }
}

.formItem {
  margin: 0 0 8px;
  width: 100%;
}
