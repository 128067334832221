@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/fonts";
@import "../../../../styles/variables/breakpoints.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  padding: 10px 0;
  align-items: center;
  background-color: $white;
}

.header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo_wrapper {

}

.header__search_wrapper {
  margin-left: 20px;
}

.header__avatar_wrapper {
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__avatar_name {
  font-size: 13px;
  font-weight: 600;
  color: lighten($darkest-gray, 10%);
  margin-right: 15px;
}

.header__avatar_img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-color: $blue;
  border-radius: 100%;
}

.dropdown_item {
  @media (min-width: $mobile_minus15) {
    font-size: 13px;
  }

  padding: 10px;
  &:hover {
    background-color: $background-gray;
    height: 100%;
  }
}


.header_mobile_wrapper {
  height: 50px;
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 8px;
  z-index: 2;
}

.header_mobile_icon {
  color: $blue;
  cursor: pointer;
}

.header_mobile_title {
  font-weight: bold;
  font-size: 18px;
}

.moreIcon {
  position: relative;
  top: 2px;
  width: 30px;
  color: #AAB3C8;
}

.headerTotal {
  color: #bec3d3;
  font-size: 14px;
  font-family: 'ManropeThin';
}

.headerTitle {
  display: inline-block;
  margin: 0 4px 0 0;
  font-size: 16px;
  font-family: 'ManropeSemibold';
}