.searchable_list {
    min-height: 60px;
    max-height: 150px;
    overflow-y: auto;
    // margin-top: 10px;
}

.search_item {
    display: flex;
    align-items: center;
    margin: 0 0 0 22px;
    padding: 5px 0;
    cursor: pointer;
}
.search_label {
    margin-left: 5px;
    font-family: 'ManropeThin';
    cursor: pointer;
}