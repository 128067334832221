@import "src/styles/variables/colors";
@import "src/styles/variables/breakpoints";

.link_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    margin-top: 20px;
    color: $gray !important;
    font-size: 18px;
    font-weight: 300;

    &:hover {
        color: $blue !important;
    }
}

.top_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .actionBarHolder > button {
        height: 40px;
    }
}

.actionBarHolder {
    display: flex;
    align-items: center;

    & > button:first-child {
        margin: 0 18px 0 0;
    } 
}

.checboxItem {
    margin: -17px 0 0;

    svg {
        margin: 0 7px 0 0;
        color: #789EFF;
    }
}

.whenHours {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    & > div:first-child {
        margin: 0 12px 0 0;
    }
}

.repeatOn {
    width: 100%;
}

.days {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;

    font-family: 'ManropeThin';

    span {
        display: inline-block;
        margin: 0 0 8px;
    }
}

.daysHolder {
    display: flex;
    align-items: center;
    margin: 0 0 30px;

    // @media screen and (max-width: $mobile) {
    @media screen and (max-width: $mobile_minus15) {
        flex-wrap: wrap;
        margin: 0;
    }
}

.day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 39px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &:not(:last-child) {
        margin: 0 7px 0 0;
    }

    & > label {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #363E59;
        background: rgba(246, 248, 254, 0.8);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    & > input[type=checkbox] {
        position: absolute;
        visibility: hidden;

        &:checked + label {
            left: 37px;
            background: #5384F9;
            color: white;
        }  
    }

    // @media screen and (max-width: $mobile) {
    @media screen and (max-width: $mobile_minus15) {

        width: 40px;
        height: 39px;
        font-size: 12px;
        margin: 0 18px 14px 0 !important;
    }
}

.endsHolder {
    display: flex;
    flex-direction: column;
    font-family: 'ManropeThin';
}

.ends {
    input[type="radio"] {
        margin: 0 24px 0 0;
        height: 40px !important;
    }
}

.endsItem {
    display: flex;
    align-items: center;

    & > div {
        width: 100%;
    }

    &:not(:last-child) {
        margin: 0 0 20px;
    }
}

.disabled {
    opacity: .5;
    pointer-events: none;
    width: 100%;
}

.repeatOn {
    margin: 0 0 30px;
}

.modalContent {
    padding: 0 20px;
    width: 100%;

    // @media screen and (min-width: 1201px) {
    @media screen and (min-width: $mobile_minus15) {
        min-width: 455px;
        min-height: 400px;
    }
}