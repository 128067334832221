@import "../../../../styles/variables/colors";

.filter_list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_list_block {
  @extend .filter_list;
  flex-direction: column;
  align-items: flex-start;
}

.filter_list_title {
  padding-right: 15px;
  font-weight: 600;
  height: 100%;
}

.filter_list_title_block {
  @extend .filter_list_title;
  font-weight: 600;
  display: block;
  color: #737E98;
  width: 100%;
  margin: 0 0 14px;
}

.filter_list_chips {
  height: 100%;
  padding: 5px;
}

.filter_list_chips_block {
  @extend .filter_list_chips;
  background-color: $background-gray;
  width: 100%;
  min-height: 55px;
  padding: 10px;
  border-radius: 10px;
}
