.password_reset {
  display: flex;
  width: 300px;
  margin: 20px auto;
  flex-direction: column;
}

.password_reset_form {
  height: 240px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
